<template>
  <div>
    <b-container>
      <div>
        <div>
          <b-breadcrumb
            style="background-color:white"
            :items="items"
          ></b-breadcrumb>
        </div>
      </div>
      <div>
        <b-row>
          <b-col>
            <h3 class="text-center">{{ Regulation.headertext }}</h3>
            <h5 class="text-center mb-3 pb-3">{{ Regulation.text }}</h5>
            <div v-html="Regulation.maintext"></div>
          </b-col>
        </b-row>
      </div>
      <div class="mt-3">
        <b-row>
          <b-col>
            <b-card class="BackgroundColor">
              <b-row>
                <b-col>
                  <p class="TextWhite17 mt-2">Bu sahifa foydali bo'ldimi?</p>
                </b-col>
                <b-col>
                  <b-button
                    v-if="!isshow"
                    @click="isshow = true"
                    style="minWidth:70px"
                    class="mr-2 mt-2 buttonTaklif"
                    >Ha</b-button
                  >
                  <b-button
                    v-if="!isshowNo"
                    @click="isshowNo = true"
                    style="minWidth:70px"
                    class="ml-2 mt-2 buttonTaklif"
                    >Yo'q</b-button
                  >
                </b-col>
                <b-col>
                  <b-button @click="Create" class="buttonTaklif mt-2 pr-5 pl-5">
                    <p class=" m-1 ">
                      <svg
                        class="mb-1 mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18.414"
                        viewBox="0 0 18 18.414"
                      >
                        <path
                          id="Контур_520"
                          data-name="Контур 520"
                          d="M64,16l-8,8v8h8l8-8-8-8"
                          transform="translate(-55 -14.586)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </svg>
                      {{ $t("write") }}
                    </p>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
import RegulationsService from "@/services/Regulations.service";
export default {
  data() {
    return {
      Regulation: {},
      isshow: false,
      isshowNo: false,
      items: [
        {
          text: this.$t("home"),
          to: { name: "Home" },
        },
        {
          text: this.$t("Regulations"),
          href: "javascript:history.go(-1)",
        },
        {
          text: this.$t("RegulationInfo"),
          active: true,
        },
      ],
    };
  },
  created() {
    RegulationsService.Get(this.$route.params.id).then((res) => {
      this.Regulation = res.data;
    });
  },
  methods: {
    Create() {
      this.$router.push({
        name: "izoh",
        params: { id: 0 },
      });
    },
  },
};
</script>
<style>
.BackgroundColor {
  background-color: #70b0ff;
  border-radius: 15px;
}
</style>
